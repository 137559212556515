import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import '../assets/css/style.css';

// Настройка модального окна (добавьте в ваш index.js)
Modal.setAppElement('#root');

const Task = () => {
    const [user, setUser] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [finances, setFinances] = useState([]);
    const [financesLoading, setFinancesLoading] = useState(false);
    const navigate = useNavigate();

    // Стили для модального окна
    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            borderRadius: '8px',
            padding: '25px',
            boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    navigate('/login');
                    return;
                }

                const [userResponse, tasksResponse] = await Promise.all([
                    axios.get('https://crm.smartcoms.ru/api/dashboard', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    }),
                    axios.get('https://crm.smartcoms.ru/api/task', {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    })
                ]);

                setUser(userResponse.data.user);
                setTasks(tasksResponse.data.news_list || []);
            } catch (error) {
                console.error('Ошибка:', error);
                setError(error.response?.data?.message || error.message);
                if (error.response?.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    const openModal = async (taskId) => {
        try {
            setFinancesLoading(true);
            const task = tasks.find(t => t.id === taskId);
            setSelectedTask(task);

            // Загрузка финансовых данных
            const response = await axios.get(`https://crm.smartcoms.ru/api/tasks/${taskId}/finances`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setFinances(response.data || []);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Ошибка загрузки финансов:', error);
            setFinances([]);
            setIsModalOpen(true);
        } finally {
            setFinancesLoading(false);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
        setFinances([]);
    };

    const createNewTask = () => {
        console.log('Создание новой задачи');
        // Реализуйте логику создания новой задачи
    };

    const editTask = (task) => {
        console.log('Редактирование задачи:', task);
        // Реализуйте логику редактирования
    };

    if (loading) {
        return (
            <div className="text-center my-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </div>
                <p>Загрузка данных...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container mt-4">
                <div className="alert alert-danger">
                    <h5>Ошибка загрузки данных</h5>
                    <p>{error}</p>
                    <button
                        className="btn btn-primary"
                        onClick={() => window.location.reload()}
                    >
                        Обновить страницу
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-4">
            <h1 className="mb-4">Панель Задач</h1>

            {user && (
                <div className="mb-4">
                    <h3>Добро пожаловать, {user.login}!</h3>
                    <p className="text-muted">Email: {user.email}</p>
                </div>
            )}

            <div className="card shadow-sm">
                <div className="card-header d-flex justify-content-between align-items-center bg-primary text-white">
                    <h4 className="mb-0">Список задач</h4>
                    <button
                        className="btn btn-light btn-sm"
                        onClick={createNewTask}
                    >
                        <i className="bi bi-plus-circle"></i> Новая задача
                    </button>
                </div>

                <div className="card-body">
                    {tasks.length > 0 ? (
                        <div className="table-responsive">
                            <table className="table table-hover align-middle">
                                <thead className="table-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Заголовок</th>
                                    <th>Регион</th>
                                    <th>Тип</th>
                                    <th>Ответственный</th>
                                    <th>Действия</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tasks.map((task) => (
                                    <tr key={task.id} className={task.status === 'completed' ? 'table-success' : ''}>
                                        <td>{task.id}</td>
                                        <td>
                                            <strong>{task.title}</strong>
                                            {task.description && (
                                                <small className="d-block text-muted">
                                                    {task.description.substring(0, 50)}...
                                                </small>
                                            )}
                                        </td>
                                        <td>{task.region?.name || task.assigned_department_name || 'Не указан'}</td>
                                        <td>
                                                <span className={`badge ${getTypeBadgeClass(task.type)}`}>
                                                    {task.type?.name || task.news_type_name || 'Не указан'}
                                                </span>
                                        </td>
                                        <td>
                                            {task.assignee?.name || task.assigned_user_fio || 'Не назначен'}
                                            {task.due_date && (
                                                <small className="d-block text-muted">
                                                    До: {new Date(task.due_date).toLocaleDateString()}
                                                </small>
                                            )}
                                        </td>
                                        <td>
                                            <div className="btn-group btn-group-sm">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    onClick={() => openModal(task.id)}
                                                    title="Просмотреть финансы"
                                                >
                                                    Финансы
                                                </button>
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    onClick={() => editTask(task)}
                                                    title="Редактировать"
                                                >
                                                    Редактировать
                                                </button>
                                                <button
                                                    className="btn btn-outline-danger"
                                                    onClick={() => console.log('Удалить', task)}
                                                    title="Удалить"
                                                >
                                                    Удалить
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="alert alert-info">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>Нет задач для отображения</span>
                                <button
                                    className="btn btn-sm btn-outline-info"
                                    onClick={() => window.location.reload()}
                                >
                                    <i className="bi bi-arrow-clockwise"></i> Обновить
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Модальное окно для финансов */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Финансы задачи"
            >
                {selectedTask && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h3>
                                Финансы задачи #{selectedTask.id}
                                <small className="ms-2 text-muted">{selectedTask.title}</small>
                            </h3>
                            <button
                                onClick={closeModal}
                                className="btn-close"
                                aria-label="Закрыть"
                            ></button>
                        </div>

                        <div className="mb-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <p><strong>Регион:</strong> {selectedTask.region?.name || 'Не указан'}</p>
                                    <p><strong>Ответственный:</strong> {selectedTask.assignee?.name || 'Не назначен'}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Статус:</strong> <span className="badge bg-primary">{selectedTask.status || 'Не указан'}</span></p>
                                    <p><strong>Срок:</strong> {selectedTask.due_date ? new Date(selectedTask.due_date).toLocaleDateString() : 'Не установлен'}</p>
                                </div>
                            </div>
                        </div>

                        <h4 className="border-bottom pb-2 mb-3">Финансовая информация</h4>

                        {financesLoading ? (
                            <div className="text-center py-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Загрузка...</span>
                                </div>
                                <p>Загрузка финансовых данных...</p>
                            </div>
                        ) : finances.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-sm">
                                    <thead>
                                    <tr>
                                        <th>Тип</th>
                                        <th>Сумма</th>
                                        <th>Дата</th>
                                        <th>Описание</th>
                                        <th>Документ</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {finances.map((item) => (
                                        <tr key={item.id}>
                                            <td>
                                                    <span className={`badge ${item.type === 'income' ? 'bg-success' : 'bg-danger'}`}>
                                                        {item.type === 'income' ? 'Доход' : 'Расход'}
                                                    </span>
                                            </td>
                                            <td>{item.amount} ₽</td>
                                            <td>{new Date(item.date).toLocaleDateString()}</td>
                                            <td>{item.description || '-'}</td>
                                            <td>
                                                {item.document_url ? (
                                                    <a href={item.document_url} target="_blank" rel="noopener noreferrer">
                                                        <i className="bi bi-file-earmark-text"></i> Просмотр
                                                    </a>
                                                ) : 'Нет'}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between mt-3">
                                    <div>
                                        <strong>Итого доходов:</strong> {finances.filter(f => f.type === 'income').reduce((sum, item) => sum + item.amount, 0)} ₽
                                    </div>
                                    <div>
                                        <strong>Итого расходов:</strong> {finances.filter(f => f.type === 'expense').reduce((sum, item) => sum + item.amount, 0)} ₽
                                    </div>
                                    <div className="fw-bold">
                                        <strong>Баланс:</strong> {finances.filter(f => f.type === 'income').reduce((sum, item) => sum + item.amount, 0) - finances.filter(f => f.type === 'expense').reduce((sum, item) => sum + item.amount, 0)} ₽
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="alert alert-warning">
                                Нет финансовых данных для этой задачи
                            </div>
                        )}

                        <div className="d-flex justify-content-end mt-4">
                            <button
                                onClick={closeModal}
                                className="btn btn-secondary me-2"
                            >
                                Закрыть
                            </button>
                            <button
                                onClick={() => console.log('Добавить финансовую запись', selectedTask.id)}
                                className="btn btn-primary"
                            >
                                <i className="bi bi-plus-circle"></i> Добавить запись
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

// Вспомогательная функция для стилей типов задач
function getTypeBadgeClass(taskType) {
    switch(taskType?.toLowerCase()) {
        case 'важная': return 'bg-danger';
        case 'обычная': return 'bg-primary';
        case 'низкая': return 'bg-secondary';
        default: return 'bg-info text-dark';
    }
}

export default Task;