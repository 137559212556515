import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const [user, setUser] = useState(null);  // Состояние для хранения данных пользователя
    const navigate = useNavigate();  // Хук для навигации

    // Эффект для проверки авторизации при загрузке компонента
    useEffect(() => {
        const fetchDashboard = async () => {
            try {
                const token = localStorage.getItem('token'); // Получаем токен из localStorage
                const response = await axios.get('https://crm.smartcoms.ru/api/dashboard', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
                setUser(response.data.user);
            } catch (error) {
                navigate('/login');
            }
        };
        fetchDashboard();
    }, [navigate]);

    // Если данные пользователя еще не загружены, показываем сообщение о загрузке
    if (!user) {
        return <div>Загрузка...</div>;
    }

    return (
        <div>
            <h1>Панель управления</h1>

            <nav className="dashboard-nav">
                <ul>
                    <li><Link to="/task">Задачи</Link></li>
                    <li><Link to="/articles">Статьи ТЦП</Link></li>
                    <li><Link to="/region">Регионы</Link></li>
                    <li><Link to="/project">Проекты</Link></li>
                    <li><Link to="/tehnika">Техника</Link></li>
                    <li><Link to="/tcp">ТЦП</Link></li>
                    <li><Link to="/agent">Контрагенты</Link></li>
                    <li>
                        <Link to="/regions" className="nav-link">
                            Управление регионами
                        </Link>
                    </li>

                </ul>
            </nav>
            <h2>Дашборд</h2>
            <p>Добро пожаловать, {user.login}!</p>
            <p>Email: {user.email}</p>
        </div>
    );
};

export default Dashboard;