import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

const RegionsPage = () => {
    const [regions, setRegions] = useState([]);
    const [newRegion, setNewRegion] = useState({
        name: '',
        code: '',
        description: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchRegions();
    }, []);

    const fetchRegions = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://crm.smartcoms.ru/api/regions', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setRegions(response.data);
        } catch (error) {
            console.error('Ошибка загрузки регионов:', error);
            navigate('/login');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewRegion(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await axios.post('https://crm.smartcoms.ru/api/regions', newRegion, {
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchRegions();
            setNewRegion({ name: '', code: '', description: '' });
        } catch (error) {
            console.error('Ошибка создания региона:', error);
        }
    };

    return (
        <div className="container">
            <h2>Управление регионами</h2>

            <div className="card mb-4">
                <div className="card-header">Добавить новый регион</div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Название</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={newRegion.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Код</label>
                            <input
                                type="text"
                                className="form-control"
                                name="code"
                                value={newRegion.code}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Описание</label>
                            <textarea
                                className="form-control"
                                name="description"
                                value={newRegion.description}
                                onChange={handleInputChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">Добавить</button>
                    </form>
                </div>
            </div>

            <div className="card">
                <div className="card-header">Список регионов</div>
                <div className="card-body">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Код</th>
                            <th>Название</th>
                            <th>Описание</th>
                        </tr>
                        </thead>
                        <tbody>
                        {regions && Array.isArray(regions) && regions.length > 0 ? (
                            regions.map(region => (
                                <tr key={region.id || region.code}>
                                    <td>{region.code || '—'}</td>
                                    <td>{region.name || '—'}</td>
                                    <td>{region.description || '—'}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className="text-center py-4">
                                    <div className="text-muted">Нет доступных регионов</div>
                                    <button
                                        className="btn btn-sm btn-primary mt-2"
                                        onClick={() => window.location.reload()}
                                    >
                                        Обновить данные
                                    </button>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RegionsPage;