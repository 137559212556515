import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import RegionsPage from './components/RegionsPage';
import TaskPage from './components/TaskPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />  {/* Страница входа */}
                <Route path="/dashboard" element={<Dashboard />} />  {/* Страница дашборда */}
                <Route path="/regions" element={<RegionsPage />} />  {/* Страница дашборда */}
                <Route path="/task" element={<TaskPage />} />
                <Route path="/" element={<Login />} />  {/* По умолчанию перенаправляем на страницу входа */}
            </Routes>
        </Router>
    );
};

export default App;