import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../assets/css/style.css'


const Login = () => {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                "https://crm.smartcoms.ru/api/auth/login", // Обратите внимание на новый путь
                { login, password },
                {
                    withCredentials: true, // Для работы с куками (если используете сессии)
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.message === "Авторизация успешна") {
                // Сохраняем токен в localStorage (для JWT)
                localStorage.setItem('token', response.data.token);

                // Сохраняем данные пользователя (если нужно)
                localStorage.setItem('user', JSON.stringify(response.data.user));

                // Перенаправляем на защищённую страницу
                navigate("/dashboard");
            }
        } catch (error) {
            if (error.response) {
                // Сервер ответил с кодом ошибки
                setError(error.response.data.message || "Неверный логин или пароль");
            } else {
                // Ошибка сети или другая проблема
                setError("Ошибка соединения с сервером");
            }
        }
    };

    return (
        <div className="limiter">
            <div
                className="container-login100"
               
            >
                <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
                    <form className="login100-form" onSubmit={handleSubmit}>
                        <span className="login100-form-title">Авторизация</span>

                        {error && <p className="errorMessage">{error}</p>}

                        <div className="wrap-input100 validate-input m-b-23">
                            <span className="label-input100">Пользователь</span>
                            <input
                                className="input100"
                                type="text"
                                placeholder="Введите логин"
                                value={login}
                                onChange={(e) => setLogin(e.target.value)}
                            />
                        </div>

                        <div className="wrap-input100 validate-input m-b-23">
                            <span className="label-input100">Пароль</span>
                            <input
                                className="input100"
                                type="password"
                                placeholder="Введите пароль"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button className="login100-form-btn" type="submit">
                                    Войти
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
